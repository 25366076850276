import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '66, 40, 29',
		'primary-dark': '30, 19, 17',
		'accent': '255, 109, 0',
		'accent-plus': '0, 0, 0',
	},
});
